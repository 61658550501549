import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './src/styles/components/swiper.css';
import './src/styles/base.scss';

import React from 'react';

import { ComponentsShell } from '@/components/shell';

export const wrapRootElement = ({ element }: any) => (
  <ComponentsShell>{element}</ComponentsShell>
);
