import React from 'react';
import type { FC, PropsWithChildren } from 'react';

import { ViewportProvider } from '@/context/viewport';

export const ComponentsShell: FC<PropsWithChildren> = ({
  children,
}) => {
  return <ViewportProvider>{children}</ViewportProvider>;
};
